<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card style="border-radius: 16px">
      <v-card-title>
        <app-title>{{ title }}</app-title>

        <app-delete-btn
          v-if="!isInsert"
          @click="handleDelete()"
          rounded
          class="ml-auto"
          type="icon"
        />
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <app-text-field v-model="form.name" label="Nome" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn outlined rounded @click="dialog = false" text> Voltar </v-btn>
        <v-btn rounded @click="handleSave()" class="" color="primary">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      form: {},
      baseForm: {
        name: null,
        status: "active",
      },
    };
  },
  computed: {
    isInsert() {
      return !this.form.id;
    },
    title() {
      if (this.isInsert) {
        return "Novo Grupo";
      }
      if (!this.isInsert) {
        return "Editar Grupo";
      }
    },
  },

  created() {
    this.reset();
  },

  methods: {
    open(id = null) {
      this.reset();
      if (id) {
        this.showGroup(id);
      }
      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    async showGroup(id) {
      await this.$http.show("group/group", id).then((response) => {
        this.form = response.group;
      });
    },

    handleSave() {
      //this.$refs.form.validate();
      if (this.isInsert) {
        this.store();
      } else {
        this.update();
      }
    },

    store() {
      this.$http.store("group/group", this.form).then((response) => {
        this.processSaved(response.group);
      });
    },

    update() {
      this.$http
        .update("group/group", this.form.id, this.form)
        .then((response) => {
          console.log(response);
          this.processSaved();
        });
    },
    handleDelete() {
      this.$loading.start();
      this.$http
        .destroy("group/group", this.form.id)
        .then((response) => {
          this.processSaved();
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    processSaved(group) {
      this.$emit("store", group);
      this.dialog = false;
    },
  },
};
</script>

<style >
</style>