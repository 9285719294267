<template>
  <div>
    <template v-if="isMobile"> <Mobile /> </template>
    <template v-if="!isMobile"> <Desktop /> </template>
  </div>
</template>

<script>
import Desktop from "@/components/group/views/Desktop.vue";
import Mobile from "@/components/group/views/Mobile.vue";

export default {
  components: {
    Desktop,
    Mobile,
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>

<style>
</style>