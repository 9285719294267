<template>
  <div>
    <portal to="mobileLeftAppBar">
      <div class="d-flex align-center">
        <v-btn
          color="primary"
          fab
          small
          elevation="0"
          @click="openStoreGroupForm()"
        >
          <app-icon>add</app-icon>
        </v-btn>
      </div>
    </portal>

    <v-row no-gutters>
      <template v-for="(group, index) in groups">
        <v-col cols="12" class="pa-3" :key="index">
          <v-card class="pa-5" outlined>
            <div class="d-flex justify-space-between align-center group-name">
              <span>{{ group.name }}</span>
              <v-btn icon small @click="openStoreGroupForm(group.id)">
                <v-icon center> mdi-pencil </v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </template>
    </v-row>

    <StoreGroupForm ref="StoreGroupForm" @store="select()" />
  </div>
</template>

<script>
import StoreGroupForm from "@/components/group/form/StoreGroupForm.vue";
export default {
  components: {
    StoreGroupForm,
  },
  data() {
    return {
      groups: [],
      loading: false,
      headers: [{ text: "Nome", align: "start", value: "name" }, ,],
    };
  },
  mounted() {
    this.select();
  },
  methods: {
    openStoreGroupForm(group_id) {
      this.$refs.StoreGroupForm.open(group_id);
    },

    select(page = 1) {
      this.$http.index("group/group", { page: page }).then((response) => {
        this.groups = response.groups;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.group-name {
  color: #33303e;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
</style>