<template>
  <div>
    <v-card outlined>
      <v-card-title
        class="d-flex justify-space-between align-center"
        style="padding-bottom: 10px !important"
      >
        <app-title> Configurações de Grupos </app-title>
        <v-btn rounded color="primary" @click="openStoreGroupForm()">
          <v-icon left> mdi-plus </v-icon>
          Novo
        </v-btn>
      </v-card-title>

      <v-card-text class="px-3">
        <v-row no-gutters>
          <template v-for="(group, index) in groups">
            <v-col cols="4" class="pa-3" :key="index">
              <v-card
                :ripple="false"
                @click="openStoreGroupForm(group.id)"
                class="pa-5"
                outlined
              >
                <div
                  class="d-flex justify-space-between align-center group-name"
                >
                  <span>{{ group.name }}</span>
                  <!-- <v-btn icon small @click="openStoreGroupForm(group.id)"> -->
                  <v-icon center> mdi-pencil </v-icon>
                  <!-- </v-btn> -->
                </div>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>
    <StoreGroupForm ref="StoreGroupForm" @store="select()" />
  </div>
</template>

<script>
import StoreGroupForm from "@/components/group/form/StoreGroupForm.vue";
export default {
  components: {
    StoreGroupForm,
  },

  data() {
    return {
      groups: [],
      loading: false,
      headers: [{ text: "Nome", align: "start", value: "name" }, ,],
    };
  },

  mounted() {
    this.select();
  },

  methods: {
    openStoreGroupForm(group_id) {
      this.$refs.StoreGroupForm.open(group_id);
    },

    select(page = 1) {
      this.$http.index("group/group", { page: page }).then((response) => {
        this.groups = response.groups;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.group-name {
  color: #33303e;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
</style>